<template>
	<div>
		<!-- 后台报表 -->
		<div class="queryBox mb20">
			<el-button size="mini" type="primary" icon="el-icon-plu" @click="addAccount('new')">新 增</el-button>
		</div>
		<template>
			<table class="dataTable" v-loading="loading">
				<thead>
					<tr>
						<th class="textCenter">账号</th>
						<th class="textCenter">代理名称</th>
						<th class="textCenter">备注</th>
						<th class="textCenter">可用余额</th>
						<th class="textCenter">冻结余额</th>
						<th class="textCenter">状态</th>
						<th class="textCenter maxwidth">功能</th>
					</tr>
				</thead>
				<tbody :style="`max-height: ${height}px;`">

					<tr class="" v-for="item in tableData" v-if="tableData && tableData.length > 0">
						<td class="textCenter">{{item.username}}</td>
						<td class="textCenter">{{item.name}}</td>
						<td class="textCenter">{{item.note}}</td>
						<td class="textCenter">{{item.money | tofixed}}</td>
						<td class="textCenter">{{item.money_uncertain | tofixed}}</td>
						<td class="textCenter"><span :class="{cerror:item.status!=0}">{{item.status===0?'正常':'冻结'}}</span></td>
						<td class="textCenter maxwidth">
							<el-link type="primary" @click="addAccount('edt',item)" class="mr20">编 辑</el-link>
							<el-link type="primary" @click="addAccount('fee',item)" class="mr20">手续费</el-link>
							<el-link type="primary" @click="resPassword(item)">重置代理密码</el-link>
						</td>
					</tr>
					<tr class="nodata" v-if="!tableData || tableData.length === 0">
						<td colspan="7"> 暂无数据</td>
					</tr>
					<tr class="">
						<td>当页总计</td>
						<td></td>
						<td></td>
						<td>{{dataTotal.current_total_money}}</td>
						<td>{{dataTotal.current_total_money_uncertain}}</td>
						<td></td>
						<td class="maxwidth"></td>

					</tr>
					<tr class="">
						<td>总计</td>
						<td></td>
						<td></td>
						<td>{{dataTotal.all_total_money}}</td>
						<td>{{dataTotal.all_total_money_uncertain}}</td>
						<td></td>
						<td class="maxwidth"></td>
					</tr>

				</tbody>
			</table>
		</template>
		<div class="flexX  mt20 pageBox" >
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
		<el-dialog width="55%" class="dialog" top="200px" :title="title" :visible.sync="dialogVisible" @close="close"
		 :close-on-click-modal="false" center>
			<div class="formBox" v-loading="dialogloading">
				<div>
					<div v-if="titleName && type === 'fee'" class="mb20">代理账号：{{titleName}}</div>
					<el-form v-if="type != 'fee'" :label-position="labelPosition" label-width="80px" :model="form">
						<el-form-item label="登录账号" v-if="type === 'edt'">
							<el-input :disabled="type != 'new'" v-model="form.agent" placeholder="请输登录账号"></el-input>
						</el-form-item>
						<el-form-item label="名称" v-if="type != 'password'">
							<el-input v-model="form.name" placeholder="请输入名称"></el-input>
						</el-form-item>
						<el-form-item label="登录账号" v-if="type === 'new'">
							<el-input :disabled="type != 'new'" v-model="form.agent" placeholder="请输入登录账号"></el-input>
						</el-form-item>
						<el-form-item label="登录密码" v-if="type === 'new'">
							<el-input type="password" autocomplete="new-password" v-model="password" placeholder="请输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="确认登录密码" v-if="type === 'new'">
							<el-input type="password" autocomplete="new-password" v-model="passwordSure" placeholder="请再次输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="状态" v-if="type != 'password'">
							<el-switch v-model="state" class="mr20"></el-switch>
							<span>{{state?'正常':'冻结'}}</span>
						</el-form-item>
						<el-form-item label="备注" v-if="type != 'password'">
							<el-input v-model="form.note" placeholder="请输入备注"></el-input>
						</el-form-item>
						<!-- 修改密码 -->
						<el-form-item label="新密码" v-if="type === 'password'">
							<el-input type="password" v-model="password" placeholder="请输入新密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" v-if="type === 'password'">
							<el-input type="password" v-model="passwordSure" placeholder="请再次输入密码"></el-input>
						</el-form-item>
					</el-form>
					<el-form v-if="type === 'fee'" :label-position="labelPosition" label-width="80px" class="mb20">
						<el-table :data="feeData" stripe size="mini" border>
							<el-table-column prop="name" label="通道名称" align="center"></el-table-column>
							<el-table-column label="手续费率(‰)" align="center">
								<template slot-scope="scope">
									<el-input style="width: 80px;" v-model="scope.row.fee" placeholder="请输入手续费" class="mr20"></el-input>
									<el-link type="primary" @click="openInnerDialog(scope.row)"> 最小值:{{scope.row.min_fee}} , 最大值:{{scope.row.max_fee}}</el-link>
								</template>
							</el-table-column>
							<el-table-column label="单笔手续费用" align="center">
								<template slot-scope="scope">
									<el-input style="width: 80px;" v-model="scope.row.fee_num" placeholder="请输入单笔手续费用" class="mr20"></el-input>
									<el-link type="primary"> 最小值:{{scope.row.min_fee_num}} , 最大值:{{scope.row.max_fee_num}}</el-link>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination :hide-on-single-page="true" @size-change="feehandleSizeChange" @current-change="feehandleCurrentChange"
						 :current-page="feepage" :page-sizes="feepageSizesArr" :page-size="feepageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="feetotal"></el-pagination>

					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
				</div>
			</div>
			<el-dialog width="30%" :title="innerDialogTitle" :visible.sync="innerVisible" append-to-body @close="closeInner"
			 :close-on-click-modal="false" center>
				<div v-loading="dialogInnerVisible">
					<el-table :data="agentTraderFeeList" stripe size="mini" border>
						<el-table-column prop="business_no" label="商家号" width="220px" align="center"></el-table-column>
						<el-table-column prop="business_name" label="商家名称" align="center"></el-table-column>
						<el-table-column prop="fee" label="手续费" align="center"></el-table-column>
					</el-table>
					<div class="textCenter mt20">
						<el-button type="primary" @click="closeInner">关 闭</el-button>
					</div>
				</div>

			</el-dialog>
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dialogloading: false,
				dialogVisible: false,
				innerVisible: false, //内层弹框
				innerDialogTitle: '', //内层弹框
				dialogInnerVisible: false, //内层弹框
				date: [],
				input: '',
				tableData: [],
				dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				feepageSizesArr: [20, 50, 100, 200],
				feepageSize: 20,
				feetotal: 0,
				feepage: 1,
				//新增
				labelPosition: 'right',
				form: {
					name: '',
					agent: '',
					password: '',
					note: '',
					state: 0
				},
				state: true,
				password: '',
				passwordSure: '', //确认密码
				type: '', //new  新增   edt修改
				id: '',
				title: '新增代理',
				titleName: '',
				submitText: '新 增',
				feeData: [],
				agentTraderFeeList: [], //手续费商家列表
				height:0
			}
		},
		created() {
			this.agentList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - 100
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			agentList() {
				// this.loading = true
				this.$api.agentList({
					page: this.page,
					size: this.pageSize
				}).then(res => {
					this.loading = false
					if (res.status === 1) {
						this.tableData = res.data.data
						this.total = res.data.total
						this.dataTotal = {
							all_total_money: res.data.all_total_money,
							all_total_money_uncertain: res.data.all_total_money_uncertain,
							current_total_money: res.data.current_total_money,
							current_total_money_uncertain: res.data.current_total_money_uncertain,
						}
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					console.log(res)
				}).catch(error => {
					this.loading = false
				})
			},
			setDate(day) {
				let data = this.date?this.date[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.agentList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.agentList()
			},
			addAccount(type, item) {
				this.dialogVisible = true
				this.type = type
				if (item) {
					this.id = item.id;
					this.form.name = item.name;
					this.form.agent = item.username;
					this.form.password = '';
					this.form.note = item.note;
					this.form.state = item.status;
					this.state = this.form.state === 0 ? true : false;
					this.password = '';
				}
				if (this.type === 'new') {
					this.title = '新增代理'
					this.submitText = '新 增'

				} else if (this.type === 'edt') {
					this.title = '编辑代理'
					this.submitText = '修 改'
				} else if (this.type === 'password') {
					this.title = '修改密码'
					this.submitText = '修 改'
				} else if (this.type === 'fee') {
					this.title = ' 手续费'
					this.submitText = '确 定'
					this.titleName = item.username
					this.agentFeeList()
				}

				// console.log(this.type,type)
				// this.$router.push('/agentAccountAdd')
			},
			feehandleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.feepage = 1
				this.feepageSize = val
				this.agentFeeList()
			},
			feehandleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.feepage = val
				this.agentFeeList()
			},
			//手续费列表查询
			agentFeeList() {
				let data = {
					agent_id: this.id,
					page: this.feepage,
					size: this.feepageSize
				}
				// this.dialogloading = true
				this.$api.agentFeeList(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.feeData = res.data.data
						this.feetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			close() {
				console.log('=====');
				this.form.name = '';
				this.form.agent = '';
				this.form.password = '';
				this.form.note = '';
				this.form.state = 0;
				this.state = true;
				this.password = '';
				this.passwordSure = '';
				this.dialogVisible = false;
				this.id = '';
				this.fee = '';
				this.feeData = [];
			},
			//内层弹框关闭
			closeInner() {
				this.innerVisible = false
				this.innerDialogTitle = ''
				this.agentTraderFeeList = []
			},
			//新增
			onSubmit() {
				//账号状态  0正常  1冻结
				this.form.state = this.state ? '0' : '1'
				this.form.password = this.$md5(this.password)
				// console.log('ddd')
				// console.log(this.type,'=====')
				if (this.type == 'new') {
					// console.log('ddaaad')
					if (isEmpty(this.form.name, '请输入代理名称')) {
						return
					}
					if (isEmpty(this.form.agent, '请输入代理账号')) {
						return
					}
					if (isIncludeHanzi(this.form.agent, '代理账号不能包含汉字')) {
						return
					}
					if (isEmpty(this.password, '请输入代理密码')) {
						return
					}
					if (this.password != this.passwordSure) {
						this.$message({
							type: 'error',
							message: '两次输入密码不一致'
						})
						return
					}
					this.dialogloading = true
					this.$api.addAgent(this.form).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.page = 1;
							// setTimeout(()=>{
							this.agentList()
							// },200)

						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
						// console.log(res)
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.type == 'edt') {
					if (isEmpty(this.form.name, '请输入代理名称')) {
						return
					}
					if (isEmpty(this.form.agent, '请输入代理账号')) {
						return
					}
					this.dialogloading = true
					console.log(this.form.name)
					let formData = {
						name: this.form.name,
						agent: this.form.agent,
						note: this.form.note,
						state: this.state ? '0' : '1',
						id: this.id,
					}
					this.$api.editAgent(formData).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							// this.page = 1;
							// setTimeout(()=>{
							this.agentList()
							// },200)
							// this.agentList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
						// console.log(res)
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.type === 'fee') {
					let errorString = ''
					var pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
					for (let i = 0; i < this.feeData.length; i++) {
						if (this.feeData[i].fee === '' || (!pattern.test(this.feeData[i].fee) && this.feeData[i].fee != 0 && this.feeData[
								i].fee != '0')) {
							errorString = '手续费只能是数字，最多两位小数'
							break;
						} else if (this.feeData[i].max_fee === '') {
							if (this.feeData[i].fee < this.feeData[i].min_fee) {
								errorString = this.feeData[i].name + ': 手续费范围不能超出商家手续费范围!'
								break;
							}
						} else if (this.feeData[i].fee > this.feeData[i].max_fee || this.feeData[i].fee < this.feeData[i].min_fee) {
							errorString = this.feeData[i].name + ': 手续费范围不能超出商家手续费范围!'
							break;
						}
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					let feeData = this.feeData.map(item => {
						return {
							"pay_type": item.pay_type,
							"fee": item.fee,
							"fee_num": item.fee_num,
						}
					})
					
					//设置手续费

					let data = {
						agent_id: this.id,
						data: JSON.stringify(feeData)
					}
					this.dialogloading = true
					console.log('feedata', this.dialogloading)
					this.$api.setAgentFee(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: '手续费设置成功'
							})
							this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}
			},
			//重置密码
			resPassword(item) {
				this.$confirm('确认重置代理' + item.username + '的密码?', '重置密码', {
					confirmButtonText: '重置',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading=true
					this.$api.setAgentPassword(data).then(res => {
						this.loading=false
						console.log(res)
						if (res.status === 1) {
							this.$alert('新密码为:' + res.data, '重置密码成功', {
								confirmButtonText: '确定',
							});
						} else {
							this.$message({
								type: 'info',
								message: res.msg
							});
						}

					}).catch(error=>{
						this.loading=false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消重置代理密码'
					});
				});

			},
			openInnerDialog(item) {
				this.innerVisible = true
				this.innerDialogTitle = item.name + ' 下商家费率列表'
				let data = {
					agent_id: this.id,
					pay_type: item.pay_type
				}
				this.dialogInnerVisible = true
				this.$api.agentTraderFeeList(data).then(res => {
					this.dialogInnerVisible = false
					if (res.status === 1) {
						this.agentTraderFeeList = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogInnerVisible = false
				})
			},


		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - 100
				})
			}
		},
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	/* 滚动条宽度 */
	   ::-webkit-scrollbar {
	       width: 0px;
	   }
	   table tbody {
	       display: block;
	       overflow-y: auto;
	       -webkit-overflow-scrolling: touch;
					border-bottom: solid 1px #ddd;
	   }	 
	   table tbody tr:last-child td{
					border-bottom: none;
	   }	 
	   table thead tr, table tbody tr, table tfoot tr {
	       box-sizing: border-box;
	       table-layout: fixed;
	       display: table;
	       width: 100%;
	   }
	   table td{
			word-break:break-all;
	   }
	   .maxwidth{
		   width: 400px;
	   }
</style>
